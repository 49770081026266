import './App.css';
import logo from './moveouttr.png'; // Import the new logo image
// Removed unused imports
// import resourceImage from './snow.png'; // Placeholder for resource image
// import checklistImage from './snow.png'; // Placeholder for checklist image
// import contactImage from './snow.png'; // Placeholder for contact image
import staticImage1 from './static-image1.png'; // Static image for phoneImage1
import staticImage2 from './static-image2.png'; // Static image for phoneImage2
import phoneImage1 from './phone-image1.png'; // Image of your app on a phone
import phoneImage2 from './phone-image2.png'; // Another image of your app on a phone
import { useState, useEffect, useCallback } from 'react';

// Custom hook for typing effect
const useTypingEffect = (text, speed) => {
  const [displayedText, setDisplayedText] = useState('');

  useEffect(() => {
    let index = 0;
    setDisplayedText(''); // Clear the text when the effect is triggered
    const interval = setInterval(() => {
      if (index < text.length) {
        setDisplayedText((prev) => prev + text.charAt(index));
        index++;
      } else {
        clearInterval(interval);
      }
    }, speed);
    return () => clearInterval(interval);
  }, [text, speed]);

  return displayedText;
};

function App() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const phoneImages = [phoneImage1, phoneImage2]; // Only include the first two images
  const staticImages = [staticImage1, staticImage2]; // Corresponding static images
  const messages = ["Sevkiyatlarınızı kolayca gerçekleştirin!", "Aracınızı paylaş sevkiyatları değerlendirin!"]; // Updated messages
  const typingSpeed = 100; // Speed of typing effect
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State for hamburger menu visibility

  const nextImage = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % phoneImages.length);
  }, [phoneImages.length]);

  // Automatically change the image every 5 seconds
  useEffect(() => {
    const interval = setInterval(nextImage, 5000); // Change image every 5 seconds
    return () => clearInterval(interval); // Clear interval on component unmount
  }, [nextImage]); // Include nextImage in the dependency array

  // Get the current message based on the current index
  const currentMessage = messages[currentIndex % messages.length];
  const displayedMessage = useTypingEffect(currentMessage, typingSpeed);

  // Function to toggle hamburger menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="App">
      <header className="App-header">
        <div className="Header-content">
          <img src={logo} alt="Logo" className="App-logo" />
          <nav className="Header-nav">
            <ul>
              <li><a href="#contact" onClick={toggleMenu}>Contact Us</a></li> {/* Update to toggle menu */}
            </ul>
          </nav>
        </div>
      </header>
      <main className="App-body">
        <div className="Image-container">
          <img src={staticImages[currentIndex]} alt="Static" className="Static-image" /> {/* Static image */}
          
          {/* Carousel for phone images */}
          <div className="Image-carousel">
            <div className="Carousel">
              <img src={phoneImages[currentIndex]} alt={`App on Phone ${currentIndex + 1}`} className="Carousel-image" />
            </div>
          </div>

          {/* Typing effect message */}
          <p className="Typing-message">{displayedMessage}</p>
        </div>

      </main>
      <footer className="App-footer">
        <p>&copy; 2024 moveout.app. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;
